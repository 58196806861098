@use "mixins/reset" as *;
@use "mixins/hide" as *;
@use "colors" as *;

.icon-button {
  $icon-button: &;

  @include reset-button;

  align-items: center;
  border-radius: .75em;
  display: flex;
  font-size: 1em;
  font-weight: 700;
  height: 32px;
  justify-content: center;
  text-align: center;
  transition: opacity .2s;
  width: 32px;

  &:focus {
    opacity: .6;
  }

  &:disabled {
    color: $color-alto;
  }

  &__content {
    @include hide-visually;
  }

  &--color-default {
    color: $color-mine-shaft;
  }

  &--color-black {
    color: $color-black;
  }

  &--outlined {
    border: 1px solid $color-neutral-3;
    padding: 8px;

    &:disabled {
      border-color: $color-alto;
    }
  }

  &--transparent {
    background: transparent;
  }

  &--medium {
    #{$icon-button}__icon {
      height: 1.5em;
      width: 1.5em;
    }
  }

  &--small {
    #{$icon-button}__icon {
      height: .75em;
      width: .75em;
    }
  }

  &--active {
    background-color: $color-hawkes-blue;
    color: $color-primary;
  }
}
