@use "mixins/modal" as *;
@use "colors" as *;

.description {
  color: $color-typo-secondary;
  margin: 0 0 4px;
}

.timeline {
  align-items: center;
  display: flex;
  gap: 10px;

  &--icon {
    color: $color-typo-secondary;
    display: inline-block;
    flex-basis: 1.714rem;
    min-height: 1.714rem;
    min-width: 1.714rem;
  }

  &--text {
    display: inline-block;
    white-space: nowrap;
  }
}

.metadata {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
  padding-bottom: 32px;
}

.user {
  align-items: center;

  & span {
    color: $color-typo-secondary;
  }
}

.completion-rate {
  display: flex;
  gap: 36px;

  &--text-input {
    flex: 0 0 48px;
    max-width: 48px;
    padding-right: 2px;
    width: unset !important;
  }

  &--slider-input {
    flex: 1 1 100%;
  }

  & > div:first-of-type {
    width: unset !important;
  }
}

.audit-record {
  color: $color-ash-gray;
  justify-content: space-between;

  &--text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &__last-update {
      flex-shrink: 0;
    }
  }
}

.overdue {
  color: $color-error;
}
